.App {
  text-align: center;
  background-color: #fffbf4;
  font-family: open-sans, sans-serif;
}

.ratingItemContainer {
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

/* Adjust width for tablets */
@media screen and (max-width: 1024px) {
  .ratingItemContainer {
    width: 20%;
  }
}

/* Adjust width for mobile devices */
@media screen and (max-width: 786px) {
  .ratingItemContainer {
    width: 25%;
  }
}

/* Adjust width for smaller mobile screens */
@media screen and (max-width: 480px) {
  .ratingItemContainer {
    width: 40%;
    align-items: normal;
  }
}

/* Adjust width for the device between 786px and 480px */
@media screen and (max-width: 768px) and (min-width: 481px) {
  .ratingItemContainer {
    width: 20%;
    align-items: normal;
  }
}

.ratingItemContainer .optionText {
  margin-top: 5px;
  font-size: 14px;
  color: #404040;
  width: 50%;
  text-align: center;
}
