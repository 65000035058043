.question-color {
  color: #384ed9;
}
.notes-label {
  background-color: #384ed9;
  font-size: 11;
  color: white;
  border-radius: 10%;
  padding: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-control {
  border: 1px solid #384ed9;
}
.form-control:focus {
  border-color: #384ed9;
  box-shadow: 0 0 0 0.2rem rgb(215, 212, 213);
}
.btn {
  background-color: #384ed9;
  border: 1px solid #384ed9;
}
.btn:hover,
.btn:focus {
  background-color: #384ed9;
  border-color: #384ed9;
  color: #fff;
}
.custom-radio-outer.secondary-radio input[type="radio"]:checked + label:after {
  background-color: #384ed9;
}
.custom-radio-outer.secondary-radio
  input[type="checkbox"]:checked
  + label:after {
  background-color: #384ed9;
}
.custom-radio-outer.secondary-radio label::after {
  border: 1px solid #384ed9;
}
.btn.border-btn:hover,
.btn.border-btn:focus {
  background-color: #384ed9;
  color: white !important;
}
.btn.border-btn.icon-btn.sm {
  color: #384ed9;
}
.custom-checkbox label:before {
  border: 2px solid #384ed9;
}

#closeNearestLocation {
  color: #384ed9;
}

.custom-checkbox input:checked + label:before {
  background: #384ed9;
}

.two-col-block {
  /* display: flex; */
  justify-content: space-between;
}

.customer-audit-row {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px;
    margin-left: -15px; */
}
.top-header-wrapper {
  max-width: 754px;
  margin: 0 auto;
}

@media (max-width: 640px) {
  #front-cust-audit-form {
    margin-top: 115px !important;
  }
}
#front-cust-audit-form {
  margin-top: 0px;
}

.btn-success-override {
  background-color: #28a745 !important;
}
.btn-danger-override {
  background-color: #dc3545 !important;
}
