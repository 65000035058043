.client-servey.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .client-servey.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(24, 24, 27, var(--tw-text-opacity));
  }
  .client-servey.font-sans {
    font-family: Cerebri Sans, ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
      sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
      Noto Color Emoji;
  }
  .client-servey.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .client-servey.overflow-x-hidden {
    overflow-x: hidden;
  }
  .client-servey.min-h-screen {
    min-height: 100vh;
  }
  
  .client-servey-custom-radio {
    position: relative;
    width: auto;
  }
  
  .client-servey-custom-radio label {
    position: relative;
    display: flex;
    height: 3rem;
    width: 3rem;
    flex: 1 1 0%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(228, 228, 231, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
    --tw-slashed-zero: slashed-zero;
    --tw-text-opacity: 1;
    color: rgba(113, 113, 122, var(--tw-text-opacity));
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    transition-duration: 0.2s;
  }
  .client-servey-custom-radio input[type="radio"] {
    visibility: hidden;
    position: absolute;
  }
  
  .client-servey .gap-y-4 {
    row-gap: 1rem;
  }
  .client-servey .gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }
  .client-servey .justify-center {
    justify-content: center;
  }
  .client-servey .items-center {
    align-items: center;
  }
  .client-servey .flex-wrap {
    flex-wrap: wrap;
  }
  .client-servey .flex {
    display: flex;
  }
  .client-servey .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .client-servey .z-0 {
    z-index: 0;
  }
  .client-servey .relative {
    position: relative;
  }
  
  .client-servey-custom-radio label.low:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .client-servey-custom-radio input:checked + label.low {
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .client-servey-custom-radio input:checked + label.low,
  .client-servey-custom-radio input:checked + label.medium {
    z-index: 10;
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  
  .client-servey-custom-radio input:checked + label.medium {
    border-color: rgba(202, 138, 4, var(--tw-border-opacity));
    background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
  }
  
  .client-servey-custom-radio label.medium:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 179, 8, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(234, 179, 8, var(--tw-text-opacity));
  }
  
  .client-servey-custom-radio input:checked + label.high {
    z-index: 10;
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .client-servey-custom-radio label.high:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  
  /* if screen size is greather than 640 */
  @media (min-width: 640px) {
    .client-servey-question-font-size {
      font-size: 2.25rem !important;
      line-height: 2.5rem !important;
    }
  }
  
  .client-servey .font-semibold {
    font-weight: 600;
  }
  .client-servey .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .client-servey .text-center {
    text-align: center;
  }
  .client-servey .max-w-2xl {
    max-width: 42rem;
  }
  .client-servey .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .client-servey .text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(82, 82, 91, var(--tw-text-opacity));
  }
  
  .client-servey.text-center {
    text-align: center;
  }
  .client-servey .py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .client-servey .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .client-servey .w-full {
    width: 100%;
  }
  .client-servey .max-w-2xl {
    max-width: 42rem;
  }
  .client-servey .mt-12 {
    margin-top: 3rem;
  }
  .client-servey .mt-1 {
    margin-top: 0.25rem;
  }
  .client-servey-textarea {
    display: block;
    width: 100%;
    border-radius: 0.375rem;
    --tw-border-opacity: 1;
    border-color: rgba(212, 212, 216, var(--tw-border-opacity));
    padding: 0.625rem 0.75rem;
  }
  
  .client-servey .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .client-servey .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(228, 228, 231, var(--tw-border-opacity));
  }
  .client-servey .border-t {
    border-top-width: 1px;
  }
  .client-servey .w-full {
    width: 100%;
  }
  .client-servey .mt-auto {
    margin-top: auto;
  }
  .client-servey .z-20 {
    z-index: 20;
  }
  .client-servey .bottom-0 {
    bottom: 0;
  }
  .client-servey .inset-0,
  .client-servey .inset-x-0 {
    right: 0;
    left: 0;
  }
  .sticky {
    position: sticky;
  }
  .client-servey .justify-between {
    justify-content: space-between;
  }
  .-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: var(--tw-transform);
  }
  .left-half {
    left: 50% !important;
  }
  .absolute {
    position: absolute;
  }
  .curntStepHighlight {
    border-radius: 20px;
    background: #fecdd3;
  }
  .client-servey .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .client-servey .ml-8 {
    margin-left: 2rem;
  }
  .client-servey ol,
  ul {
    list-style: none;
  }
  .client-servey a {
    color: inherit;
    text-decoration: inherit;
  }
  
  .client-servey .btn-primary {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  @media (min-width: 640px) {
    .client-servey .btn {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  
  .client-servey .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: transparent;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    transition-duration: 0.3s;
  }
  .client-servey [role="button"],
  button {
    cursor: pointer;
  }
  .client-servey .btn-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 18, 60, var(--tw-bg-opacity));
  }
  .client-servey .p-px {
    padding: 1px;
  }
  .client-servey .w-5 {
    width: 1.25rem;
  }
  .client-servey .h-5 {
    height: 1.25rem;
  }
  .client-servey .bg-rose-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(225, 29, 72, var(--tw-bg-opacity));
  }
  .client-servey .bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
  }
  .client-servey .rounded-full {
    border-radius: 9999px;
  }
  .client-servey .w-full {
    width: 100%;
  }
  .client-servey .h-full {
    height: 100%;
  }
  .client-servey .w-2\.5 {
    width: 0.625rem;
  }
  .client-servey .h-2\.5 {
    height: 0.625rem;
  }
  .client-servey .block {
    display: block;
  }
  .client-servey .relative {
    position: relative;
  }
  
  .client-servey .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  /* 
  .client-servey .hidden {
      display: none;
  } */
  /* if screen size is == mobile */
  @media (min-width: 640px) {
    .sm\:flex {
      display: flex;
    }
  
    .hidden {
      display: show;
    }
  }
  /* if screen size is more than mobile */
  @media (max-width: 640px) {
    .hidden {
      display: none;
    }
  }
  
  .client-servey-less-padding {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .client-servey .ml-8 {
    margin-left: 2rem;
  }
  
  .client-servey .max-w-4xl {
    max-width: 56rem;
  }
  
  .btn-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(24, 24, 27, var(--tw-text-opacity));
  }
  
  /* .btn-gray-client-servey:click{
      border-radius: 20px;
      background: #fecdd3;
  } */
  /* .curntStepHighlight{
      border-radius: 20px;
      background: #fecdd3;
  } */
  .btn-gray {
    --tw-border-opacity: 1;
    border-color: rgba(212, 212, 216, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(63, 63, 70, var(--tw-text-opacity));
  }
  
  /* step4 from css */
  @media (min-width: 1024px) {
    .lg-grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }
  }
  
  .gap-6 {
    gap: 1.5rem;
  }
  .items-stretch {
    align-items: stretch;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid {
    display: grid;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .font-medium {
    font-weight: 500;
  }
  .block {
    display: block;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  @media (min-width: 640px) {
    .sm-flex-none {
      flex: none;
    }
  }
  
  .flex-1 {
    flex: 1 1 0%;
  }
  
  .client-servey-custom-checkbox {
    position: relative;
    height: 100%;
    width: auto;
  }
  
  @media (min-width: 640px) {
    .client-servey-custom-checkbox label {
      height: 100%;
      width: 202px !important;
    }
  }
  .client-servey-custom-checkbox label {
    height: 100%;
    width: 100%;
    /* cursor: pointer; */
    overflow: hidden;
    border-radius: 0.5rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(244, 244, 245, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(82, 82, 91, var(--tw-text-opacity));
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    transition-duration: 0.2s;
  }
  
  .client-servey-custom-checkbox.green input:checked + label {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  
  .client-servey-custom-checkbox.green input[type="checkbox"] {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .client-servey-custom-checkbox input[type="checkbox"] {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    border-radius: 9999px;
    border-style: none;
  }
  /* input[type=checkbox]:checked {
      background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E);
  } */
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    border-color: transparent;
    background-color: var(--my_color);
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  
  .cust-servey-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .cust-servey-sr-only-label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgba(63, 63, 70, var(--tw-text-opacity));
  }
  
  @media (min-width: 640px) {
    .sm-py-6 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
  .client-servey-checkbox-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .client-servey-checkbox-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .w-8 {
    width: 2rem;
  }
  
  .h-8 {
    height: 2rem;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (min-width: 1024px) {
    .lg\:col-span-4 {
      grid-column: span 4 / span 4 !important;
    }
  }
  
  .col-span-2 {
    grid-column: span 2 / span 2;
  }
  
  .client-servey-custom-checkbox.primary input[type="checkbox"] {
    --tw-text-opacity: 1;
    color: rgba(225, 29, 72, var(--tw-text-opacity));
  }
  
  .client-servey-custom-checkbox.primary input:checked + label {
    --tw-border-opacity: 1;
    border-color: rgba(225, 29, 72, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 241, 242, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(225, 29, 72, var(--tw-text-opacity));
  }
  
  @media (min-width: 1024px) {
    .lg\:gap-x-12 {
      -moz-column-gap: 3rem;
      column-gap: 3rem;
    }
  }
  
  .text-center {
    text-align: center;
  }
  .gap-x-6 {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  .client-servey-custom-checkbox-landscape {
    position: relative;
    height: 100%;
    width: auto;
  }
  
  @media (min-width: 640px) {
    .client-servey-custom-checkbox-landscape label {
      height: 100%;
      width: 262.62px !important;
    }
  }
  .client-servey-custom-checkbox-landscape label {
    height: 100%;
    width: 100%;
    /* cursor: pointer; */
    overflow: hidden;
    border-radius: 0.5rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(244, 244, 245, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(82, 82, 91, var(--tw-text-opacity));
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    transition-duration: 0.2s;
  }
  
  .client-servey-custom-checkbox-landscape.green input:checked + label {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(123, 239, 178, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  
  .client-servey-custom-checkbox-landscape.green input[type="checkbox"] {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .client-servey-custom-checkbox-landscape input[type="checkbox"] {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    border-radius: 9999px;
    border-style: none;
  }
  .gap-x-5 {
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .gap-y-4 {
    row-gap: 1rem;
  }
  
  .resize-y {
    resize: vertical;
  }
  .form-control-client-servey:focus {
    outline: none !important;
    border: 2px solid #e11d48;
    box-shadow: none !important;
    border-radius: 0.25rem !important;
    font-size: 0.875rem !important;
  }
  .form-control-client-servey::placeholder {
    font-size: 0.875rem !important;
  }
  .form-control-client-servey {
    border-radius: 0.25rem !important;
  }
  .max-w-2xl {
    max-width: 42rem;
  }
  .place-items-center {
    place-items: center;
  }
  .text-rose-600 {
    --tw-text-opacity: 1;
    color: rgba(225, 29, 72, var(--tw-text-opacity));
  }
  .w-32 {
    width: 8rem;
  }
  
  .h-32 {
    height: 8rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  
  .btn-gray-client-servey {
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity)) !important;
    color: #563f46 !important;
  }
  .btn-gray-client-servey:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1;
    color: rgba(24, 24, 27, var(--tw-text-opacity)) !important;
  }
  .client-servey-custom-checkbox-count {
    background: rgba(3, 166, 120, 1) !important;
  }
  
  .client-servey-custom-checkbox-count {
    position: absolute;
    top: 2rem;
    right: 0.75rem;
    border-radius: 8px;
    border-style: none;
    background: #e11d48;
    padding: 2px;
    color: white;
    height: 17px;
    width: 17px;
  }
  .client-servey-count-order {
    top: -2px;
    color: white;
    position: absolute;
    right: 5px;
    font-size: 13px;
    font-weight: bold;
  }
  
  .form-input-label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgba(63, 63, 70, var(--tw-text-opacity));
  }
  
  .client-servey-custom-checkbox-landscape label:hover {
    --tw-border-opacity: 1;
    border-color: rgba(82, 82, 91, var(--tw-border-opacity));
  }
  
  .client-servey-custom-checkbox label:hover {
    --tw-border-opacity: 1;
    border-color: rgba(82, 82, 91, var(--tw-border-opacity));
  }
  .underline {
    text-decoration: underline;
  }
  .underline:hover {
    color: #e11d48;
  }
  
  /* Custom CSS By Vishal */
  .enterDiv {
    border: 2px solid black;
    border-radius: 20px;
    padding: 30px;
  }
  
  .client-survey-btn,
  .client-survey-btn:hover,
  .client-survey-btn:active {
    background-color: rgba(225,29,72) !important;
    color: #fff !important;
    width: 73.42px;
    height: 38px;
    text-transform: none;
    border-color: rgba(225,29,72) !important;
  }
  
  .client-survey-next-btn,
  .client-survey-previous-btn,
  .client-survey-finish-btn {
    width: 73.42px;
    height: 38px;
    text-transform: none;
  }  