.flex{
    display: flex;
}
.box{
    padding-left: 0px;
    padding-right: 0px;
}
.heading {
    background: #00922F;
    color: white;
    text-align: left;
    height: 30px;
}
.heading > p{
    font-weight: bold;
    padding: 5px
}
/* 
 */
.contain {
margin-top: 30px;
margin-bottom: 30px;
text-align: left;
}
.contain > p{
    margin-left: 20px;
}
.subcontain{
    margin-top: 15px;
margin-bottom: 15px;
}
.button{
  margin-left: auto;
  margin-right: 0px;
}
.button > button{
    margin: 2px;
    color: white;
    border: none;
    background: #008000ad;
}
.graph{
    border: 2px solid #008000ad ;
    text-align: center;
    margin: 0px;
}
#q-graph {
  display: block; /* fixes layout wonkiness in FF1.5 */
  position: relative; 
  width: 600px; 
  height: 277px;
  margin: 1.1em 0 0; 
  padding: 0;
  background: transparent;
  font-size: 11px;
      left: 12%;
}
#q-graph caption {
  caption-side: top; 
  width: 600px;
  text-transform: uppercase;
  letter-spacing: .5px;
  top: -40px;
  position: relative; 
  z-index: 10;
  font-weight: bold;
}
#q-graph tr, #q-graph th, #q-graph td { 
  position: absolute;
  bottom: 0; 
  width: 67px; 
  z-index: 2;
  margin: 0; 
  padding: 0;
  text-align: center;
}

#q-graph td {
  transition: all .3s ease;
}
#q-graph:hover {
    background-color: desaturate(#85144b, 100);
    opacity: .9;
    color: white;
  }
#q-graph thead tr {
  left: 100%; 
  top: 50%; 
  bottom: auto;
  margin: -2.5em 0 0 5em;}
#q-graph thead th {
  width: 7.5em; 
  height: auto; 
  padding: 0.5em 1em;
}
#q-graph thead th.sent {
  top: 0; 
  left: 0; 
  line-height: 2;
}
#q-graph thead th.paid {
  top: 2.75em; 
  line-height: 2;
  left: 0; 
}
#q-graph tbody tr {
  height: 277px;
  padding-top: 2px;
  border-right: 1px dotted #C4C4C4; 
  color: #AAA;
}
#q-graph #q1 {
  left: 0;
}
#q-graph #q2 {left: 65px;}
#q-graph #q3 {left: 128px;}
#q-graph #q4 {left: 192px; border-right: none;}
#q-graph tbody th {bottom: -1.75em; vertical-align: top;
font-weight: normal; color: #333;}
#q-graph .bar {
  width: 20px; 
  border: 1px solid; 
  border-bottom: none; 
  color: #000;
}
#q-graph .bar p {
  margin: 5px 0 0; 
  padding: 0;
  opacity: .4;
}
#q-graph .sent {
  left: 13px; 
  background-color: #39cccc;
  border-color: transparent;
}
#q-graph .paid {
  left: 40px; 
  background-color: #7fdbff;
  border-color: transparent;
}
#ticks {
  position: relative; 
  top: -99px; 
  left:55px;
  width: 596px; 
  height: 300px; 
  z-index: 1;
  margin-bottom: -300px;
  font-size: 10px;
  font-family: "fira-sans-2", Verdana, sans-serif;
}
#ticks .tick {
  position: relative; 
  border-bottom: 1px dotted #C4C4C4; 
  width: 315px;
}
#ticks .tick p {
  position: absolute; 
  left: -5em; 
  top: -0.8em; 
  margin: 0 0 0 0.5em;
}
.line-chart-block, .bar-chart-block {
    height: 316px;
}
    .line-chart {
        height: 200px;
        background: whitesmoke;
    }
.grafico {
    padding: 2rem 1rem 1rem;
    width: 100%;
    height: 100%;
    position: relative;
    color: black;
    font-size: 80%;
  }
  .grafico span {
    display: block;
    position: absolute;
    bottom: 3rem;
    left: 2rem;
    height: 0;
    border-top: 2px solid;
    transform-origin: left center;
  }
  .grafico span > span {
    left: 100%; bottom: 0;
  }
  [data-valor='25'] {width: 75px; transform: rotate(-45deg);}
  [data-valor='8'] {width: 24px; transform: rotate(65deg);}
  [data-valor='13'] {width: 39px; transform: rotate(-45deg);}
  [data-valor='5'] {width: 15px; transform: rotate(50deg);}
  [data-valor='23'] {width: 69px; transform: rotate(-70deg);}
  [data-valor='12'] {width: 36px; transform: rotate(75deg);}
  [data-valor='15'] {width: 45px; transform: rotate(-45deg);}
  
  [data-valor]:before {
    content: '';
    position: absolute;
    display: block;
    right: -4px;
    bottom: -3px;
    padding: 4px;
    background: green;
    border-radius: 50%;
  }
  [data-valor='23']:after {
    content: '+' attr(data-valor) '%';
    position: absolute;
    right: -2.7rem;
    top: -1.7rem;
    padding: .3rem .5rem;
    background: #50597B;
    border-radius: .5rem;
    transform: rotate(45deg);  
  }
  [class^='eje-'] {
    position: absolute;
    left: 0;
    bottom: 0rem;
    width: 100%;
    padding: 1rem 1rem 0 2rem;
    height: 80%;
  }
  .eje-x {
    height: 2.5rem;
  }
  .eje-y li {
    height: 25%;
    border-top: 1px solid #777;
  }
  [data-ejeY]:before {
    content: attr(data-ejeY);
    display: inline-block;
    width: 2rem;
    text-align: right;
    line-height: 0;
    position: relative;
    left: -2.5rem;
    top: -.5rem;
  } 
  .eje-x li {
    width: 10%;
    float: left;
    
  }
  .img{
      height: 50px;
      width: 50px;
  }
  .line-chart{
    background-color: white;
  }
  
/* new css  */
  .reporting-page-px-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.reporting-page-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (min-width: 768px){
  .md\:text-right {
    text-align: right;
  }
 
}

.reporting-page-rounded-full {
  border-radius: 9999px;
}
.reporting-page-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: #00922f;
}

.rp-justify-center {
  justify-content: center;
}
.rp-items-center {
  align-items: center;
}
.rp-flex-shrink-0 {
  flex-shrink: 0;
}
.rp-w-12 {
  width: 3rem;
}
.rp-h-12 {
  height: 3rem;
}
.rp-flex {
  display: flex;
}
.rp-ml-4 {
  margin-left: 1rem;
}

.rp-font-bold {
  font-weight: 700;
}

.rp-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.rp-leading-tight {
  line-height: 1.25;
}

.rp-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.rp-mt-1 {
  margin-top: 0.25rem;
}
.rp-items-start {
  align-items: flex-start;
}

.rp-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}
.rp-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.rp-h-full {
  height: 100%;
}
.rp-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}
.rp-h-17{
height: 270px!important;
}
.rp-h-334{
height: 334px!important;
}
.rp-border-dashed {
  border-style: dashed!important;
  border:2px solid;
  border-radius: 5px!important;
}
.rp-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229,231,235,var(--tw-border-opacity));
}
.rp-bg-grey{
  background-color: rgba(229,231,235,var(--tw-border-opacity));
}
.rp-uppercase{
  text-transform: uppercase;
}

@media (min-width: 640px){
  .rp-sm\:text-right {
    text-align: right;
  }
  .rp-sm\:pt-5 {
    padding-top: 1.25rem;
  }
  .rp-md\:text-right{
    text-align: right!important;
  }
}
.rp-md\:text-right{
  text-align: left;
}

.rp-score-header{
  float: left;
    width: 50%;
    text-align: center;
}

.rp-w-auto {
  width: auto;
}

.rp-h-10 {
  height: 2.5rem;
}

.rp-underline:hover {
  color:#00922F;
}
.underline {
  text-decoration: underline;
}

.rp-text-purple-600 {
    --tw-text-opacity: 1;
    color: #00922f;
}

.rp-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.rp-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (max-width: 720px){
  .order-one {order:2!important}
  .order-two {order:1!important}
  .rp-sm-font-size{
    font-size: 17px!important;
  }
}
.order-one {order:1}
.order-two {order:2}
