/* Style for the modal background */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* Style for the modal box */
.modal-box {
  max-height: 98%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 10000;
  overflow-y: auto;
}

@media screen and (max-width: 1200px) {
  .modal-box {
    width: 80%;
  }
}

@media screen and (max-width: 992px) {
  .modal-box {
    width: 90%;
  }
}
