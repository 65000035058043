.custom-input-box {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: 14px !important;
  overflow: hidden;
  font-family: sans-serif;
}
.custom-input-box input {
  background: #fff;
  border: none;
  outline: none;
}
.custom-input-box .prefix {
  font-weight: 300;
  font-size: 1rem;
  color: #000;
  width: 35px;
}
