.signature-canvas {
  border: 1px solid #ccc;
}
.close-btn {
  background-color: transparent;
  font-size: 0.8rem;
  min-width: unset;
  height: 30px;
  line-height: 1rem;
  color: rgb(64, 64, 64);
  position: absolute;
  top: 4px;
  right: 2px;
  text-transform: capitalize;
  border-radius: 15px;
}
.signature-box {
  position: relative;
  max-width: 400px;
}
