.info-word-break{
    word-break: break-all;
}


@media (max-width: 640px){
    .feedback-stepper-wrapper{
        margin-top:90px!important;
    }
}

.feedback-stepper-wrapper{
    margin-top:0px;
}

.emp-feedback-slick-slide{
    width: 228px!important;
}

.emp-feedback-slick-track{
    opacity: 1;
    width: 920px;
    transform:translate3d(0px, 0px, 0px);
}

.emp-feedback-h2-heading{
    padding-bottom: 10px;
}


@media (min-width:1170px){
    .emp-feedback-h1-heading{
        position: absolute;
    }
}
@media (min-width: 640px) and (max-width:756px){
    .emp-feedback-h1-heading{
        margin-top: 20%;
    }
}

.emp-feedback-slide-btn-parent{
    margin-left:70%;
}