.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 600px) {
  .formContainer {
    display: block;
  }
}

.contactContainer {
  display: flex;
  justify-content: space;
  flex-direction: row;
  align-items: center;
}

.imgContainer {
  height: 27px;
  min-width: 27px;
  justify-content: center;
  display: flex;
}

.address {
  font-size: 20px;
  font-weight: 400;
  margin-left: 18px;
  margin-bottom: 0;
}

.websiteImg {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 33px;
}

.btnContainer {
  margin-top: 117px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  .btnContainer {
    flex-direction: column;
  }
}

.cancelBtn {
  width: 49%;
  border-radius: 10px;
  border: 2px solid #6e247f;
  color: #6e247f;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
}

@media (max-width: 600px) {
  .cancelBtn {
    width: auto;
  }
}

.contactUsBtn {
  width: 49%;
  background: #6e247f;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
}
@media (max-width: 600px) {
  .contactUsBtn {
    width: auto;
    margin-top: 2%;
  }
}

.containerWidth {
  width: 49%;
}

@media (max-width: 600px) {
  .containerWidth {
    width: auto;
  }
}

.reachUs {
    margin-top: 0;
}

@media (max-width: 600px) {
    .reachUs {
      margin-top: 2%;
    }
  }
