/* Page */
.page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

p {
  font-size: 1rem;
}

/* Header */
.page-header {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-logo {
  width: 150px;
  margin: 10px;
  object-fit: contain;
  max-height: 100px;
}

/* Body */
.page-body {
  overflow: hidden;
  display: block;
  height: 100%;
  width: 70%;
}

/* Intro page */
.intro-logo-container {
  width: 75%;
  padding: 1rem;
}

.intro-logo-div {
  background: white;
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  object-fit: cover;
  max-width: 100%;
}

@media (min-width: 768px) {
  .responsive-image {
    max-width: 50%;
  }
}

.avatar {
  width: 50px;
  border-radius: 50%;
}
.intro-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  height: 100%;
}
.intro-left-1 {
  height: 90%;
}
.intro-left-2 {
  height: 10%;
}

.intro-message {
  height: 420px;
  overflow: auto;
}
.intro-right {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

/* Content page */
.content-wrapper {
  overflow: auto;
}

.content-file {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  width: 26%;
  max-width: 26%;
  height: max-content;
  min-height: 67%;
  border: 1.75px solid transparent;
  transition: 0.1s ease;
  border-radius: 8px;
}
.content-file:hover {
  border: 1.75px solid gainsboro;
}
.content-image {
  width: 100%;
  padding: 1rem;
  object-fit: contain;
}
.content-name {
  margin-top: 0.5rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.125rem;
}
.content-button {
  text-align: center;
  color: white;
  text-decoration: none;
  background: #34d399;
  color: white;
  width: 50%;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 20px;
}
.content-button:hover {
  color: white;
  background: #10b981;
}
.content-button:disabled {
  color: white;
  background: #6ee7b7;
}
.arrow-button {
  padding: 10px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 2rem;
  color: darkgray;
  transition: 0.1s ease;
}
.arrow-button:hover {
  color: gray;
}
.arrow-button:active {
  color: black;
}
.arrow-button:disabled {
  color: gainsboro;
}

/* Footer */
.page-footer {
  width: 100%;
  background: white;
}
.footer-content {
  padding: 35px 0;
  width: 70%;
}

.question-card {
  overflow: hidden;
  height: 92%;
  width: 100%;
  background: white;
  border-radius: 20px;
  /* padding: 3rem 4rem; */
  margin-top: 30px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 1280px) {
  .page-body {
    width: 80%;
  }
  /* .question-card { */
  /*   padding: 2rem 3rem; */
  /* } */
  .intro-message {
    height: 325px;
    overflow: auto;
  }
}

@media screen and (max-width: 1024px) {
  .intro-message {
    height: 200px;
    overflow: auto;
  }
  .content-file {
    padding: 0.5rem 1rem;
    margin: 1.5rem 0.5rem;
    width: 42%;
    max-width: 42%;
  }
}

/* Styles for screens 992px or smaller */
@media screen and (max-width: 992px) {
  .intro-left {
    width: 100%;
  }
  .intro-message {
    height: 100%;
    overflow: auto;
  }
  .intro-right {
    width: 100%;
  }
  .question-card {
    /* padding: 2rem 3rem; */
    height: 93%;
    overflow-y: auto;
  }
  .footer-content {
    width: 80%;
  }
  .page-body {
    width: 90%;
  }
  .content-file {
    margin: 1.5rem 1rem;
    width: 42%;
    max-width: 42%;
  }
}

/* Styles for screens 480px or smaller */
@media screen and (max-width: 480px) {
  .footer-content {
    width: 90%;
  }
  .question-card {
    /* padding: 2rem 2rem; */
    height: 92%;
    overflow-y: auto;
  }
  .content-file {
    margin: 1.5rem 1rem;
    width: 100%;
    max-width: 100%;
  }
}
