.input-box {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: 14px !important;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 1rem;
  color: #000;
  width: 35px;
}

.input-box input {
  background: #fff;
  border: none;
  outline: none;
  padding: 16.5px 14px 18.5px 0px !important;
}

.input-box:focus-within {
  border-color: #777;
}
.contact-number .MuiOutlinedInput-notchedOutline {
  border: 0;
}
