.c-modal-wrapper {
  display: flex;
  align-items: start;
  height: 1000px;
}
.c-modal-left {
  width: 70%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: gainsboro;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 2px solid lightgray;
}
.c-left-top {
  width: 100%;
  height: calc(100vh - 9rem);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.c-left-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}
.c-modal-image {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin: 2rem;
}

.c-modal-right {
  width: 30%;
}
.c-modal-right-file {
  display: none;
}
.c-modal-action {
  width: 100%;
  display: flex;
  justify-content: end;
}
.c-modal-close {
  margin: 8px 10px;
  border: 1px solid transparent;
  border-radius: 50%;
  text-align: center;
  width: 32px;
  color: gray;
  font-size: 1.25rem;
  transition: 0.1s ease;
}
.c-modal-close:hover {
  border: 1px solid gainsboro;
  background: whitesmoke;
}
.c-modal-right-content {
  padding: 0 20px 20px 20px;
}
.c-modal-content-head {
  font-size: 1rem;
}
.c-modal-content-name {
  font-size: 1.75rem;
  font-weight: normal;
}
.c-modal-content-title {
  font-size: 1.125rem;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .c-modal-left {
    display: none;
  }
  .c-modal-right {
    width: 100%;
    font-size: 0.9rem;
  }
  .c-modal-content-title {
    font-size: 1rem;
    font-weight: bold;
  }
  .c-modal-content-name {
    font-size: 1.5rem;
    font-weight: normal;
  }
  .c-modal-right-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
  }
  .c-modal-image {
    width: 200px;
    height: 200px;
    margin: 0.5rem;
  }
}
