.welcome-container {
  display: flex;
  height: 100vh;
}

.left-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.right-section {
  width: 50%;
}

.logo-container {
  display: flex;
  justify-content: center;
  max-height: 100px;
  margin-bottom: 20px;
  width: 100%; /* Ensure it takes full width of its parent */
  margin-top: 1%;
}

.description-text {
  text-align: center;
  padding: 25px;
}

.start-button {
  display: flex;
  justify-content: center;
  width: 100%; /* Full width of its parent */
  margin-top: 20px;
}

.no-description {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-description-logo {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  display: flex;
}

.loading-image {
  height: 500px;
  width: 500px;
}

.termAndCondition {
  color: black;
  width: 100%;
  text-align: center;
  margin: 15px auto;
}

/* Mobile view styles */
@media screen and (max-width: 768px) {
  .welcome-container {
    flex-direction: column;
    align-items: center; /* Center align for all children */
  }

  .left-section,
  .right-section {
    width: 100%;
    order: 2; /* Place both sections below the logo by default */
  }

  .logo-container {
    order: 1; /* Logo comes first */
  }

  .left-section {
    order: 3; /* Left section content comes after the logo */
    margin-top: 10px;
  }

  .logo-container {
    max-height: 100px;
  }
  .loading-image {
    height: 300px;
    width: 300px;
  }
}
.rs-picker-toggle {
  display: flex !important;
  align-items: center !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0 !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: darkgray;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 4px;
}
.rs-picker-toggle {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
