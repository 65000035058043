.ppHeader{
    position: relative;
    background-color: #6e247f !important;
    width: 100%;
    z-index: 50;
    padding:32px 0px;
    text-align: center;
    
}
.ppTitle{
    color:#fff
}