.single-question {
  width: 33.3%;
}

.text-align-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.MuiFormLabel-root {
  color: #404040 !important;
}

.option-button {
  color: #404040;
  padding: 0.35rem 0.5rem;
  border: 1px solid white;
  border-radius: 5px;
  min-width: 35px;
  transition: 0.15s ease;
}
.option-button:hover {
  background-color: #f9fafb;
  border: 1px solid #9ca3af;
}
.option-button:active,
.option-button.selected {
  font-weight: bold;
  border: 1px solid #404040;
  background-color: #e8e8e8;
}
.rating-button {
  padding: 0.35rem;
  border: 1px solid #404040;
}
.radio-button {
  text-align: left;
  min-width: 150px;
}
.checkbox-button {
  text-align: left;
  min-width: 100%;
}
.emoji-button {
  padding: 2px;
  border-radius: 50%;
}
.emoji-button:hover {
  background-color: #f9fafb;
  border: 1px solid gainsboro;
}
.emoji-button:active,
.emoji-button.selected {
  border: 1px solid #9ca3af;
  background-color: #e8e8e8;
}

@media only screen and (max-width: 990px) {
  .single-question {
    width: 100%;
  }
}
