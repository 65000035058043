input[type="checkbox"]:checked,
input[type="radio"]:checked {
  border-color: transparent !important;
  background-color: #36473f !important;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.answerWhite {
  padding: 0.6rem 1rem;
}
.answerArea {
  max-width: 280px;
  word-wrap: break-word;
  width: auto;
  text-align: right;
}
/* Define the grid container */
.answerImage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px; /* Adjust the gap size as needed */
  place-content: end;
}
.gap-2 {
  gap: 2px;
}
.answerImage div {
  border: 1px solid gainsboro;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100px;
}
.answerImage div img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.comment {
  font-size: 13px;
  margin-bottom: 0 !important;
  text-align: left;
  margin-right: 2px;
}
.comment span {
  font-weight: 500;
}
.progress-bar-container {
  width: 50%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  margin-right: auto;
}

.progress-bar {
  width: 100%; /* Set to the initial progress value */
  height: 100%;
  animation: progressAnimation 1s ease-in-out; /* Add animation to the progress bar */
}

@keyframes progressAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.chip:not(:last-child) {
  margin-bottom: 0.25rem !important; /* Adjust the margin as needed */
}
.height-1 {
  height: 1rem;
}
.rate-btn {
  width: 80%;
}
.submit-btn {
  width: 20%;
}
.comment-btn {
  height: 38px !important;
  vertical-align: bottom !important;
}
.signature-canvas-block {
  height: 100%;
}
