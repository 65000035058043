.m-t-30
{
	margin-top: 30px;
}
.m-t-80
{
	margin-top: 80px;
}
.scroll-margin
{
	margin-bottom: 80px;
}
.margin-0px
{
	margin-bottom: 0px;	
}
.thank-you-text
{
	text-align: center;
font-weight: 600;
margin-top: 50%;
font-size: 25px;
}
.home-button
{
	text-align: center;
margin-left: 45%;
margin-top: 10px;
}

/* custom style */
.bg-purple-600 {
    --tw-bg-opacity: 1;
    --tw-company-color: 230, 0, 80;
    background-color: rgba(var(--tw-company-color), var(--tw-bg-opacity));
    }
    .text-purple-600 {
    --tw-text-opacity: 1;
    --tw-company-color: 230, 0, 80;
    color: rgba(var(--tw-company-color), var(--tw-text-opacity));
}
.border-purple-600 {
    --tw-border-opacity: 1;
    --tw-company-color: 230, 0, 80;
    border-color: rgba(var(--tw-company-color), var(--tw-border-opacity));
}

.focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-company-color: 230, 0, 80;
    --tw-ring-color: rgba(--tw-company-color, var(--tw-ring-opacity));
}
.focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    --tw-company-color: 230, 0, 80;
    border-color: rgba(var(--tw-company-color), var(--tw-border-opacity));
}

#mobile-view-modal-footer{
  display: revert;
}

#mobile-view-modal-title {
  padding: 0px 0px;
}
#mobile-view-modal-body {
  padding: 0px 0px;
}
.modal-body-listing-child{
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}
