.react-responsive-modal-overlay-a{
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.react-responsive-modal-modal-a {
    /* max-width: 800px; */
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    border-radius: 20px;
    /* overflow-y: auto; */
    /* min-height: 530px; */
}