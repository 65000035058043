.questionview {
  height: 81vh;
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .questionview {
    height: 81vh;
  }
  .emoji {
    width: 50px;
    height: 50px;
  }
}

/* Landscape */
/* @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { */
@media only screen and (min-width: 430px) and (orientation: landscape) {
  .questionview {
    height: 61vh;
  }
  .emoji {
    width: 50px;
    height: 50px;
  }
  .questionContainer {
    max-width: 100%;
  }
  .answerContainer {
    max-width: 100%;
  }
}
