.rating-feedback-prev-btn:hover {
  background-color: rgb(149, 82, 117) !important;
  color: rgb(255, 255, 255);
}
.rating-feedback-prev-btn {
  color: rgb(149, 82, 117) !important;
}
.custom-radio-outer.web-rating-secondary-radio
  input[type="radio"]:checked
  + label:after {
  background-color: rgb(149, 82, 117) !important;
}
.custom-radio-outer.web-rating-secondary-radio label::after {
  border: 1px solid rgb(149, 82, 117) !important;
}
.h1-heading {
  font-size: 28px !important;
  line-height: 1.2 !important;
  font-weight: 400 !important;
}
.h1-heading-home {
  font-size: 32px !important;
  line-height: 1.2 !important;
  font-weight: 400 !important;
  margin-bottom: 47px !important;
}
.web-rating-custom-checkbox-label {
  font-weight: 500 !important;
}
.form-control-web-ratting:focus {
  outline: none !important;
  border: 1px solid rgb(149, 82, 117);
  box-shadow: none !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
}
