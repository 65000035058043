.MuiFormControlLabel-root {
  min-width: 175px !important;
  user-select: none;
  margin-left: -8px !important;
  margin-right: 10px !important;
}
.MuiFormControl-root {
  margin-left: 0px !important;
  width: 100% !important;
}
.MuiFormControlLabel-label {
  padding: 8px 30px;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #9ca3af !important;
  border-width: 1.5px !important;
}
.MuiBox-root {
  /* padding-left: 2px !important; */
  margin-bottom: 0 !important;
}
.radioInline {
  display: "flex";
  flex-direction: "row !important";
  justify-content: "space-between";
  margin-left: 8px !important;
}
.radioItem {
  border: 1px solid white;
  margin-bottom: 0px !important;
}
.radioItem:hover {
  transition: 0.15s ease;
  border: 1px solid #9ca3af !important;
  background-color: #f9fafb !important;
}

.ratingItem:hover {
  transition: 0.15s ease;
  border: 1px solid #9ca3af !important;
  background-color: #f9fafb !important;
}
.boldOnSelect > .MuiFormControlLabel-label {
  font-weight: 500 !important;
}
.MuiFormControlLabel-label {
  padding: 6px 6px;
}
.MuiIconButton-root {
  padding: 0 10px !important;
}
.seqSelectionIndex {
  font-size: 14px !important;
  color: #9ca3af;
  padding-bottom: 2px;
  letter-spacing: 0.1ch !important;
  margin-left: 10px;
  margin-right: 2px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .MuiFormControlLabel-root {
    margin-left: 0 !important;
  }
  .MuiBox-root {
    padding-left: 0 !important;
  }
  .MuiIconButton-root {
    padding: 0 0 0 10px !important;
  }
  .MuiFormControl-root {
    margin-left: 0 !important;
  }
  .radioInline {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    margin-left: 0 !important;
  }
  .MuiFormControlLabel-root {
    justify-content: start !important;
  }
  .MuiFormControlLabel-root {
    justify-content: center;
    width: 100% !important;
    margin-right: 0 !important;
  }
  .MuiFormControlLabel-label {
    font-size: 14px !important;
    padding: 6px 15px;
  }
}

.MuiInputBase-root {
  color: black !important;
}
