.rs-picker-toggle {
  display: flex !important;
  align-items: center !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0 !important;
  border-right: 1px solid #e5e5ea !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: darkgray;
}
