.container {
  background-color: #e5e5e5;
  padding-top: 66.5px;
  padding-bottom: 97px;
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 60px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* @media (max-width:550px) {
    .container {
        grid-template-columns: 100%;
    }  
} */

.divrow {
  display: flex;
  flex-direction: row;
}

/* body {
    zoom: 0.8;
} */

.logoimg {
  padding: 7px;
  margin-bottom: 12px;
  width: 200px;
}
@media (max-width: 768px) {
  .divrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoimg {
    text-align: center;
  }
}

.divbackground1 {
  background-color: #ffffff;
  margin: 8px;
  min-height: 109px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 22px;
  align-items: center;
  align-content: center;
}

.logoCard {
  background-color: #ffffff;
  margin: 8px;
  min-height: 109px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 22px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 820px) {
  .logoCard {
    background-color: #ffffff;
    margin: 8px;
    min-height: 109px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 22px;
    align-items: center;
    align-content: center;
  }
}
@media (max-width: 768px) {
  .logoCard {
    flex-direction: row;
  }
}
/* @media (max-width: 1000px )  {
  .divbackground1{flex-direction: column;}
}
@media (max-width: 840 )  {
  .divbackground1{flex-direction: row;}
} */
.dateCard {
  background-color: #ffffff;
  margin: 8px;
  min-height: 109px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 22px;
  align-items: center;
  align-content: center;
}
@media (max-width: 820px) {
  .dateCard {
    background-color: #ffffff;
    margin: 8px;
    min-height: 109px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 22px;
    align-items: center;
    align-content: center;
  }
}
@media (max-width: 768px) {
  .dateCard {
    flex-direction: row;
  }
}
.divbackground3 {
  background-color: #ffffff;
  margin: 8px;
  min-height: 172px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;

  align-items: center;
  align-content: center;
}

.divbackground4 {
  background-color: #ffffff;
  margin: 8px;
  max-height: 380px;
  overflow-y: auto;
  border-radius: 10px;
  display: block;
  padding: 20px;
  align-items: center;
  align-content: center;
}

.divbackground5 {
  background-color: #ffffff;
  margin: 12px;
  min-height: 123px;
  border-radius: 10px;
  /* display: flex; */
  padding: 20px;
  /* align-items: center; */
  align-content: center;
}

.divbackground6 {
  background-color: #ffffff;
  margin: 8px;
  min-height: 656px;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .divbackground6 {
    min-height: 200px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  margin-top: 12px;
}

@media (max-width: 768px) {
  /* body {
        zoom: 0.6;
    } */

  .grid {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 550px) {
  .grid {
    grid-template-columns: 100%;
  }
}

.grid1 {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 0px;
}
@media (max-width: 820px) {
  .grid1 {
    grid-template-columns: 50% 50%;
  }
}
@media (max-width: 768px) {
  .grid1 {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 550px) {
  .grid1 {
    grid-template-columns: 100%;
  }
}

.grid2 {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0px;
}

.gridFull {
  display: grid;
  grid-template-columns: 100%;
  gap: 0px;
}

@media (max-width: 768px) {
  .grid2 {
    grid-template-columns: 100%;
  }
}

.grid3 {
  display: grid;
  grid-template-columns: 100%;
}

.iconGrid {
  margin: 7px;
  font-size: 4rem;
}
@media (max-width: 820px) {
  .iconGrid {
    font-size: 2.5rem;
  }
}

.icon1 {
  margin: 13px;
}

.iconbackground {
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .iconbackground {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.iconGrid1 {
  margin: 7px;
  font-size: 2rem;
  color: #ffffff;
}
@media (max-width: 1024px) {
  .iconGrid1 {
    font-size: 1.5rem;
  }
}
.keyPoints {
  margin-left: 10px;
  list-style: disc;
}
.heading {
  margin: 7px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  color: #424343;
}
@media (max-width: 820px) {
  .heading {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .heading {
    font-size: 18px;
  }
}
.onlyheading {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  color: #424343;
  margin-bottom: 0;
}
.heading1 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  color: #424343;
  margin-bottom: 12px;
}

.heading2 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  color: #424343;
}

.para {
  margin: 2px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  color: #424343;
}
.datePara {
  margin: 2px;
  margin-right: 0px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  color: #424343;
}
@media (max-width: 820px) {
  .datePara {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .datePara {
    font-size: 15px;
  }
}
.para1 {
  margin-top: 10px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  color: #424343;
}

.para2 {
  margin: 2px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  color: #424343;
  width: 100%;
}

.para3 {
  margin: 2px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  color: #424343;
  display: flex;
  justify-content: center;
}

.span {
  font-family: "Inter";
  font-weight: 700;
  font-size: 15px;
  color: #424343;
}

.span1 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  color: #424343;
}

.numberpercent {
  margin-top: -10px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 36px;
  color: #424343;
}
@media only screen and (min-width: 600px) {
  .number-percent {
    font-size: 25px;
  }
}

@media (max-width: 820px) {
  .heading1 {
    font-size: 16px;
  }
  .heading2 {
    font-size: 15px;
  }
  .numberpercent {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .heading1 {
    font-size: 16px;
  }
  .heading2 {
    font-size: 15px;
  }
  .numberpercent {
    font-size: 26px;
  }
}
.button {
  color: #ffffff;
  width: 131px;
  height: 36px;
  border-radius: 5px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  border: none;
}

.button1 {
  background-image: linear-gradient(90deg, #ee381b, #f2ce17);

  color: #ffffff;
  width: 269px;
  height: 20px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  border: none;
  align-content: center;
}

.button2 {
  background-color: #4fad1c;

  color: #ffffff;
  width: 100%;
  height: 20px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  border: none;
  align-content: center;
}

.tableScal {
  font-family: Inter;
}

@media (max-width: 600px) {
  .tableScal {
    zoom: 0.7;
  }
}

.button3 {
  background-color: #f4f4f4;
  display: flex;
  color: #424343;
  width: 100%;
  height: 36px;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  border: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.main div:nth-child(odd) {
  background: #f4f4f4;
  /* height: 40px; */
  display: flex;
  align-items: center;
  padding-left: 18px;
  /* justify-content: center; */
}

.main1 div:nth-child(odd) {
  /* height: 40px; */
  display: flex;
  align-items: center;
  padding-left: 18px;
  /* justify-content: center; */
}

.tableScal > tbody tr:nth-child(odd) {
  background: #f4f4f4;
}

table {
  border-collapse: collapse;
}

.main td:nth-child(2),
.main1 td:nth-child(2) {
  text-align: center;
}

.ratingEmojis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0.7rem 0.2rem 0.7rem;
}

.emoji {
  width: 4rem;
  padding-bottom: 0.8rem;
}

@media (max-width: 1200px) {
  .emoji {
    width: 3rem;
  }
  .button3,
  .span1 {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .emoji {
    width: 3rem;
  }
  .button3,
  .span1 {
    font-size: 13px;
  }
}
@media (max-width: 600px) {
  .emoji {
    width: 3rem;
  }
  .button3,
  .span1 {
    font-size: 13px;
  }
}

.emojiContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottomPercentsRow {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}
.outercircle {
  display: flex;
  align-items: center;
}
.onePercent {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circlePercent {
  border: 1px solid green;
  padding: 10%;
  border-radius: 50%;
  height: 60px !important;
  width: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dangerCirclePercent {
  border: 1px solid red;
  padding: 10%;
  border-radius: 50%;
  height: 60px !important;
  width: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signs {
  width: 5px;
  height: 55px;
  margin: 10px;
  font-size: 22px;
  font-weight: bold;
}
